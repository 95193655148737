<template>
  <secondary-page-layout :toHomePage="true">
    <v-dialog/>
    <div class="form-group py-3">
      <send-gift-to-friend class="mb-4" :invoice-id="invoiceId" v-if="insuranceType === 1000" />

      <div v-if="!rateSaved">
        <div class="mb-4">
          <p class="text-center mb-4">
            {{ $t('pleaseRateService') }}
          </p>
          <rating-stars @input="val => serviceRating.rating = val" />
        </div>
        <div class="mb-4">
          <p class="text-center mb-4">
            {{ $t('pleaseRateBonuses') }}
          </p>
          <rating-stars @input="val => bonusRating.rating = val" />
        </div>
        <div class="mb-4">
          <p class="text-center mb-4">
            {{ $t('pleaseRateManager') }}
          </p>
          <rating-stars @input="val => managerRating.rating = val" />
        </div>
        <div class="feedback">
          <p class="text-center mb-4">{{ $t('comments') }}</p>
          <textarea class="textarea" :placeholder="$t('write')" v-model="comment"></textarea>
        </div>
        <button class="primary-btn w-100" @click="submit">
          {{ $t('leaveFeedback') }}
        </button>
      </div>
    </div>
  </secondary-page-layout>
</template>

<script>
import SendGiftToFriend from "../components/SendGiftToFriend";
import RatingStars from "../components/RatingStars";
import FeedbackResultModal from "../components/Modals/FeedbackResultModal";
import {mapGetters} from "vuex";
import eventLogger from "../services/eventLogger";

export default {
  name: "SendFeedback",
  // eslint-disable-next-line vue/no-unused-components
  components: { RatingStars, SendGiftToFriend, FeedbackResultModal },
  data() {
    return {
      rateSaved: false,

      serviceRating: {
        rating: null,
        type: 'service'
      },
      bonusRating: {
        rating: null,
        type: 'bonus'
      },
      managerRating: {
        rating: null,
        type: 'manager'
      },
      comment: null,
    };
  },
  computed: {
    ...mapGetters(['INSURANCE_PRODUCT']),
    insuranceType() {
      return Number(this.INSURANCE_PRODUCT?.insurance_type);
    },
    avgRating() {
      return (this.serviceRating.rating + this.bonusRating.rating + this.managerRating.rating) / 3;
    },
    invoiceId() {
      return this.$route?.query?.invoiceId
    },
    feedbackText() {
      if(this.avgRating < 3) {
        return this.$i18n.t('lowFeedback')
      } else if(this.avgRating === 3) {
        return this.$i18n.t('mediumFeedback')
      } else {
        return this.$i18n.t('highFeedback')
      }
    }
  },
  methods: {
    redirect() {
      this.$router.push('/');
    },

    async submit() {
      const loader = this.$loading.show();

      let rate = {
        InvoiceID: this.invoiceId,
        ServiceRank: this.serviceRating.rating || 0,
        BonusRank: this.bonusRating.rating || 0,
        ManagerRank: this.managerRating.rating || 0,
        comment: this.comment,
      }

      try{
        let { data } = await window.axios.post("https://797.polisonline.kz/api/SetRatings", rate);

        this.rateSaved = true

        //Если успешно сохранен отзыв
        if (data.statusCode === 200) {
          this.$modal.show('dialog', {
            title: this.$i18n.t('rateSaved'),
            text: this.feedbackText,
            buttons: [{
              title: this.$i18n.t('goToMain'),
              handler: () => {
                this.redirect();
              }
            }]
          }, {class: 'success'});
        } else if (data.statusCode === 400) {
          //Если отзыв был ранее
          this.$modal.show('dialog', {
            text: this.$i18n.t('alreadyRatedInvoice'),
            buttons: [{
              title: this.$i18n.t('goToMain'),
              handler: () => {
                this.redirect();
              }
            }]
          }, {class: 'error'});
        }
      }catch (e) {
        // при ошибке сохранении
        this.$modal.show('dialog', {
          title: 'Произошла ошибка',
          text: e?.response?.data?.message || e?.message,
          buttons: [{
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
              return null;
            }
          }]
        }, {class: 'error'});
      } finally {
        loader.hide();
      }
    },
  },


};
</script>

<style lang="scss" scoped>
.feedback {
  .textarea {
    height: 120px;
    margin-bottom: 24px;
  }
}
</style>
